import { Mail, MapPin } from 'lucide-react';

export default function Contact() {
  return (
    <div id="contact" className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Contact Us
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            We welcome your inquiries and will provide professional solutions
          </p>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            <div className="bg-white rounded-lg shadow-lg p-8 text-center">
              <div className="flex justify-center">
                <Mail className="h-10 w-10 text-indigo-600" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">Email</h3>
              <p className="mt-2 text-base text-gray-500">
                allen@i365.me
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 text-center">
              <div className="flex justify-center">
                <MapPin className="h-10 w-10 text-indigo-600" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">Address</h3>
              <p className="mt-2 text-base text-gray-500">
                1800 S 22nd Ave<br />
                Ste 120<br />
                Bozeman, MT 59718
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}