import { Code, Database } from 'lucide-react';

const services = [
  {
    title: 'Enterprise Software + AI Solutions',
    description: 'Based on enterprise AI needs, we provide customized AI application solutions to improve operational efficiency. Combined with AI technology, we create intelligent solutions for enterprises.',
    icon: Code,
  },
  {
    title: 'Data AI Smart Analytics Solutions',
    description: 'Big data analysis and artificial intelligence applications to help enterprise intelligent decision-making. Providing data mining, predictive analytics, and intelligent recommendation services.',
    icon: Database,
  },
];

export default function Services() {
  return (
    <div id="services" className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Our Services
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
            Providing "+ AI" technology solutions for enterprises
          </p>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 max-w-4xl mx-auto">
            {services.map((service) => (
              <div key={service.title} className="pt-6">
                <div className="flow-root bg-white rounded-lg px-6 pb-8 h-full">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-600 rounded-md shadow-lg">
                        <service.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{service.title}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}