import { Code2 } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center space-x-6">
          <a href="#" className="flex items-center space-x-2">
            <Code2 className="h-8 w-8 text-indigo-600" />
            <span className="text-xl font-bold text-gray-900">I365 Studio</span>
          </a>
        </div>
        <div className="mt-8 text-center">
          <p className="text-base text-gray-400">
            &copy; {new Date().getFullYear()} I365 Studio LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}