export default function About() {
  return (
    <div id="about" className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            About Us
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            I365 Studio is a dynamic enterprise AI technology innovation company
          </p>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <img
                className="rounded-lg shadow-lg object-cover"
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80"
                alt="Our team"
              />
            </div>
            <div className="flex flex-col justify-center">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Our Vision
              </h3>
              <p className="text-gray-600 mb-6">
                We are dedicated to helping enterprises achieve AI digital capability transformation through AI innovative technology, improving operational efficiency and competitiveness. As your trusted technology partner, we will always stay at the forefront of technology to provide you with the highest quality solutions.
              </p>
              <p className="text-gray-600">
                With our professional technical team and rich project experience, we can provide comprehensive technical support for customers, from requirement analysis to solution design, from development implementation to operation and maintenance support, ensuring your success throughout the journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}